<template>
  <div>
   <!-- <input type="text" class="form-control" v-model="lookupValue"  
    @blur = "blur()"
    @input = "search()"
    @keydown.enter = 'enter'
    @keydown.down = 'down'
    @keydown.up = 'up'
    @keydown.esc = 'esc'
    :disabled='lockEntry'> -->
    <v-text-field type="text" class="form-control" v-model="lookupValue"  
    @blur = "blur()"
    @focus = "focus()"
    @input = "search()"
    @keydown.enter = 'enter'
    @keydown.down = 'down'
    @keydown.up = 'up'
    @keydown.esc = 'esc'
    :disabled='lockEntry' :label='label' autofocus/>
    <div class="panel-footer" v-if="open" @click = "click()">
      <ul class="ui-autocomplete ui-menu" @click = "click()">
        <li v-if="isBasicLookup" class="list-group-item" v-bind:class="{'active': isActive(index)}" v-for="row, index in list" @mouseover="MouseOver(index)" @click = "click()"> 
          {{row.id}}
        </li>
        <li v-if="!isBasicLookup" class="list-group-item" v-bind:class="{'active': isActive(index)}" v-for="row, index in list" @mouseover="MouseOver(index)" @click = "click()"> 
          {{row.name}}<br/><small>{{row.id }}</small>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  mounted: function()
  {
    if (this.lookupId !== null && this.lookupId != '')
    {
      var newVal = this.lookupId
      // console.log(this.$refs.inputRef)

      // if(this.$refs.inputRef)
      // {
      //   this.$refs.inputRef.focus()
      // }
      if(this.allowApiCall == 1)
      {
        axios.get(this.url, {params: {q: newVal, q2:this.q2}})
        .then(response => 
        {
          this.open = false
          
          if (response.data != null)
          {
            this.list = response.data.data//.list
            this.item = this.list[0]
            if (this.item !== undefined)
            {
              this.lookupValue = this.item.name
              this.selectedId = this.item.id
              this.$emit('selected', this.item)
            }
            this.open = false
          }
        })
      }
      else
      {
        this.lookupValue = this.lookupName
      }

    }
    
    ////console.log("mounted")
    this.lockEntry = this.disabled
    this.isBasicLookup = this.basic
  },
  props: 
    ['url','lookupId', 'q2', 'selected', 'disabled', 'basic', 'label', "allowApiCall", "lookupName"]
  ,
  watch: 
  { 
    disabled: function(newVal, oldVal)
    {
      this.lockEntry = newVal
    },
    lookupId: function(newVal, oldVal)
    { 
      if (newVal == null || newVal == "")
      {
        this.lookupValue = ""
        this.selectedId = null
        this.item = null
        this.list = []
        this.current = -1
        this.$emit('blur', this.item)
      }
      else
      {
        if (newVal != '')
        {

          if(this.allowApiCall == 1)
          {
              axios.get(this.url, {params: {q: newVal, q2:this.q2, get:1}})
            .then(response => 
            {
              this.isFirstLoad = false
              this.open = false
              
              this.list = response.data.data
              if (this.list.length>0)
              {
                this.item = this.list[0]
                if (this.item !== null)
                {
                  this.selectedId = this.item.id
                  this.lookupValue = this.item.name
                  this.$emit('selected', this.item)
                }
              }
              this.open = false
            })
          }
          else
          {
            this.lookupValue = this.lookupName
          }

 
        }
        else
        {
          this.item.id = ""
          this.item.name = ""
          this.selectedId = this.item.id
          this.lookupValue = this.item.name
          this.$emit('selected', this.item)
        }
      }        
    }
    
  },
  data () {
    return {
      lookupValue: '',
      isBasicLookup: false,
      list: [],
      open: true,
      selectedId: '',
      item: {},
      current: -1,
      lockEntry: false,
      isFirstLoad: true
    }
  },
  methods:{
    clear: function(val)
    {
        this.lookupValue = ""
        this.selectedId = null
        this.item = {id:"", name: ""}
        this.list = []
        this.current = -1
    },
    search(forceSearch = false) {

    this.list = []
      if (this.lookupValue != '' || forceSearch)
      {
        //////console.log("search - a")
        this.open = true
        this.current = -1
        this.selectedId = ''

        if(this.allowApiCall)
        {
            axios.get(this.url, {params: {q: this.lookupValue, q2: this.q2}}).then(response => {
            this.list = []
            this.list = response.data.data
            if (this.list.length == 0)
            {
              this.current=-1
              this.selectedId = ""
              this.lookupValue = ""
              this.item = {id:"", name: ""}
              this.$emit('selected', this.item)
            }
            else
            {
              //////console.log("search - c")
              this.current=0
              this.item = this.list[this.current]
              this.open = true
              //////console.log(response.data.data)
            }
          })
        }

      }
      else
      {
          //////console.log("search - d")
          this.current=-1
          this.selectedId = ""
          this.item = {id:"", name: ""} 
          this.$emit('selected', this.item)
      }
      //////console.log("search end")
    },
    focus()
    {
      //console.log(this.$parent.next2())
    },
    blur()
    {
      var wasOpen = this.open
      //console.log("blur start")
      //this.allowApiCall = 1
      this.open = false
      
      if (this.item !== null)
      {
        
        if (this.item.id == this.selectedId && this.item.name == this.lookupvalue)
        {
          //console.log("all same")
        }
        else if (this.lookupValue == '' && !wasOpen)
        {
          this.selectedId = ""
          this.lookupValue = ""
          this.item = {id:"", name:""}
          this.$emit('selected', this.item)
        }
        else if (this.lookupValue == '' && wasOpen)
        {
          this.item = this.list[this.current]
          if (this.item !== undefined)
          {
            this.selectedId = this.item.id
            this.lookupValue = this.item.name
          }
          else
          {
            this.item = {id:"", name: ""}
            this.selectedId = this.item.id
            this.lookupValue = this.item.name

          }
          this.$emit('selected', this.item)
        }
        else if (this.current >= 0 && this.list.length > 0 && this.selectedId == "")
        {
          this.item = this.list[this.current]

          if (this.item !== undefined)
          {
            if (this.item.id !== undefined)
            {
              this.selectedId = this.item.id
              this.lookupValue = this.item.name
            }
          }
          
          this.$emit('selected', this.item)
        }
        else if (this.item.id == "" && this.lookupValue != '')
       {
          this.selectedId = ""
          this.lookupValue = ""
          this.item.id = this.selectedId
          this.item.name = this.lookupValue

          this.$emit('selected', this.item)
        }
        else if (this.item.id != this.selectedId || this.item.name != this.lookupValue)
        {
          this.current = -1
          this.selectedId=""
          this.lookupValue=""
          this.item.id = this.selectedId
          this.item.name = this.lookupValue

          this.$emit('selected', this.item)
        }
      }
      else
      {
        this.lookupValue = ""
      }

      this.$emit('blur', this.item)
    },
    click()
    {
      if (this.current < this.list.length-1)
      {
        this.item = this.list[this.current]
        this.$emit('selected', this.item)
      }
      //console.log("CLICK")
    },
    MouseOver(index)
    {
      this.current = index
      //console.log(this.$refs.lookupBox)


    },
    esc()
    {
      this.open = false
    },
    enter () {
      //////console.log("enter start")
      if (this.list.length)
      {
        if (this.current >= 0)
        {
          this.item = this.list[this.current]
          this.selectedId = this.item.id
          this.lookupValue = this.item.name
          this.$emit('selected', this.item)
        }
        this.open = false
      }
    },
    up () 
    {
      if (this.current > 0 && this.open) {
        this.current--
      }
    },
    down () 
    {
      if (this.lookupValue == '' && !this.open)
      {
        this.search(true)
        this.current=0
      }
      else if (this.current < this.list.length - 1 && this.open) {
        this.current++
      }
      else
      {
        this.search(true)
        this.current=0
      }
    },
    isActive (index) 
    {
      return index === this.current
    },    
  }
}
</script>
<style>
        .ui-menu
        {
            float: left;
            list-style: none outside none;
            margin: 0;
            padding: 2px;
            background-color: aliceblue;
        }
        .ui-autocomplete
        {
            cursor: default;
            position: absolute;
            z-index: 9999
        }
        .ui-menu .ui-menu-item
        {
            clear: left;
            float: left;
            margin: 0;
            padding: 0;
            width: 100%;              
        }
        .ui-menu .ui-menu-item a
        {
            display: block;
            padding: 3px 3px 3px 3px;
            text-decoration: none;
            cursor: pointer;
            background-color: Green;
        }
        .ui-menu .ui-menu-item a:hover
        {
            display: block;
            padding: 3px 3px 3px 3px;
            text-decoration: none;
            color: White;
            cursor: pointer;
            background-color: ButtonText;
        }
</style>
